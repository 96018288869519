import styles from './Gallery.module.css';
import galleryData from '../resources/gallery';
import { useEffect, useState } from 'react';

const Gallery = () => {
    // TODO Update structure of gallery we don't need root array
    const [filter, setFilter] = useState('All');
    const [gallery, setGallery] = useState([]);
    const [categories, setCategories] = useState(['All']);

    useEffect(() => {

        let modifiedGallery = [];

        if (filter === 'All') {
            galleryData.forEach((category) => {
                modifiedGallery.push(...Object.values(category).flat());
            });
        } else {
            galleryData.forEach((category) => {
                if (Object.keys(category)[0] === filter) {
                    modifiedGallery.push(...Object.values(category).flat());
                }
            });
        }
        setGallery(modifiedGallery);

    }, [filter]);

    useEffect(() => {
        let modifiedCateogries = ['All']
        let allImages = [];
        galleryData.forEach((category) => {
            modifiedCateogries.push(Object.keys(category));
            allImages.push(Object.values(category).flat());
        });
        setCategories(modifiedCateogries);
        setGallery(allImages.flat());
    }, []);

    return (
        <div className={styles.section}>
            <h2>Gallery</h2>
            <div className={styles.galleryInteractions}>
                <label htmlFor="filter">Category</label>
                <select onChange={(e) => setFilter(e.target.value)} value={filter}>
                    {categories.map((category) => (
                        <option key={category} value={category}>
                            {category}
                        </option>
                    ))}
                </select>
            </div>
            <div className={styles.galleryDisplay}>
                {gallery.map((image) => (
                    <img key={image.id} src={image.url} alt={image.label} />
                ))}
            </div>
        </div>
    );
};

export default Gallery;