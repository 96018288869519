import styles from './Testimonial.module.css';

const Testimonial = () => {
    return (
        <div className={styles.section}>
            <h2>Testimonials</h2>
            <p>Work in progress.</p>
            <p>Soon you will be able to hear from our happy customers!</p>
        </div>
    )
}

export default Testimonial;