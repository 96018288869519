import styles from './Contact.module.css';

const Contact = () => {

    const handleContactFormSubmission = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = {};
        formData.forEach((value, key) => {
            data[key] = value;
        });
        console.log(data);
        event.target.reset();
    }

    return (
        <div className={styles.section}>
            <form onSubmit={handleContactFormSubmission} className={styles.contactForm}>
                {/* TODO Give us an opportunity to serve you */}
                <h2>Contact Us</h2>
                <fieldset>
                    <div className={styles.contactFormLabels}>
                        <label htmlFor="name">Name</label>
                        <label htmlFor="email">Email</label>
                        <label htmlFor="address">Address</label>
                        <label htmlFor="phone">Phone</label>
                    </div>
                    <div className={styles.contactFormInputs}>
                        <input type="text" id="name" name="name" required />
                        <input type="email" id="email" name="email" required />
                        <input type="text" id="address" name="address" required />
                        <input type="tel" id="phone" name="phone" required />
                    </div>
                </fieldset>
                <div className={styles.contactFormButtons}>
                    <button type="reset">Reset</button>
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    )
}

export default Contact;