import styles from './Footer.module.css';

const Footer = () => {
    return (
        <div className={styles.section}>
            <h2>Footer</h2>
            <p>Work in progress.</p>
            <p>Credits, Copyrights, Policies, Social Media.</p>
        </div>
    )
}

export default Footer;