import { useContext } from 'react';
import styles from './Header.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCouch, faPhone, faBars, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import ExcelContext from './ExcelContext';

const Header = () => {
    const pages = [
        { name: 'Home', path: '#Home', subPages: ['Home', 'About', 'Testimonial', 'Contact', 'Footer'] },
        // { name: 'About', path: '#About' },
        // { name: 'Testimonial', path: '#Testimonial' },
        // { name: 'Contact', path: '#Contact' },
        { name: 'Gallery', path: '#Gallery', subPages: ['Gallery'] },
        { name: 'Shop', path: '#Shop', subPages: ['Shop'] }
    ]

    const { excel, setExcel } = useContext(ExcelContext);

    const call = () => {
        window.location.href = 'tel:+918668381410';
    }

    const goTo = (page) => () => {
        if (page === 'Home') {
            setExcel({ ...excel, page, serviceGalleryScroll: true });
        } else {
            setExcel({ ...excel, page, serviceGalleryScroll: false });
        }
        // const element = document.getElementById(page);
        // if (element) {
        //     element.scrollIntoView({ behavior: 'smooth' });
        // }
    }

    return (
        <div className={styles.header_group}>
            <header className={styles.header}>
                <div className={styles.brand}>
                    <FontAwesomeIcon className={styles.logo} icon={faCouch} />
                    <h1 className={styles.brand_name}>Excel<span className={styles.brand_name_service_one}>&nbsp;Furniture</span><span className={styles.brand_name_service_two}>&nbsp;&&nbsp;Fabricators</span></h1>
                </div>
                <nav className={styles.horizontal_nav}>
                    <ul className={styles.horizontal_page_links}>
                        {pages.map((page, index) => (
                            <li onClick={goTo(page.name)} key={index}><a
                                style={{ borderTop: page.subPages.includes(excel.page) ? '2px solid var(--tertiary-color)' : '', color: page.subPages.includes(excel.page) ? 'var(--second-accent-light-color)' : 'var(--neutral-light-color)' }}>{page.name}</a></li>
                        ))}
                    </ul>
                </nav>
                <button onClick={call} className={styles.horizontal_enquire_btn}>
                    <FontAwesomeIcon className={styles.phone_icon} icon={faPhone} />
                    &nbsp;&nbsp;+91 86683 81410
                </button>
                <button onClick={call} className={styles.compact_enquire_btn}>
                    <FontAwesomeIcon className={styles.phone_icon} icon={faPhone} />
                </button>
            </header>
            <nav className={styles.vertical_nav}>
                <ul className={styles.vertical_page_links}>
                    {pages.map((page, index) => (
                        <li onClick={goTo(page.name)} key={index}><a style={{ borderBottom: page.subPages.includes(excel.page) ? '2px solid var(--tertiary-color)' : '', color: page.subPages.includes(excel.page) ? 'var(--second-accent-light-color)' : 'var(--neutral-light-color)' }}>{page.name}</a></li>
                    ))}
                </ul>
            </nav>
        </div >
    );
};

export default Header;