import styles from './Main.module.css';
import ServiceGallery from './ServiceGallery';
import AboutUs from './AboutUs';
import Testimonial from './Testimonial';
import Footer from './Footer';
import Contact from './Contact';
import ExcelContext from './ExcelContext';
import { useContext, useRef, useEffect } from 'react';

const Main = () => {

    const {excel, setExcel} = useContext(ExcelContext);

    const homeRef = useRef(null);
    const aboutRef = useRef(null);
    const testimonialRef = useRef(null);
    const contactRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setExcel({ ...excel, page: entry.target.id });
                }
            });
        }, { threshold: 0.5 });

        observer.observe(homeRef.current);
        observer.observe(aboutRef.current);
        observer.observe(testimonialRef.current);
        observer.observe(contactRef.current);

        return () => observer.disconnect();
    }, []);

    return (
        <main className={styles.main}>
            <section id="Home" ref={homeRef}>
                <ServiceGallery />
            </section>
            <section id="About" ref={aboutRef}>
                <AboutUs />
            </section>
            <section id="Testimonial" ref={testimonialRef}>
                <Testimonial />
            </section>
            <section id="Contact" ref={contactRef}>
                <Contact />
            </section>
        </main>
    );

}

export default Main;