import styles from './Shop.module.css';

const Shop = () => {
    return (
        <div className={styles.section}>
            <h2>Shop</h2>
            <p>Work in progress.</p>
            <p>Soon you will be able to get an instant free quote from us!</p>
        </div>
    )
}

export default Shop;