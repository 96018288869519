import './App.css';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Excel from './components/Excel';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

ReactGA.initialize('G-GCMM214XXL');

function App() {

  const PageViewTracker = () => {
    const location = useLocation();
    useEffect(() => {
      // Log pageview with current path
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);
    return null; // This component doesn't render anything
  };

  return (
    <BrowserRouter>
      <PageViewTracker />
      <Routes>
        <Route path="/" element={<Navigate replace to="/excel" />} />

        <Route path="*" element={<Navigate replace to="/excel" />} />

        <Route path="/excel" element={<Excel />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
