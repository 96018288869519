import styles from './ServiceGallery.module.css';
import imgModularKitchenWide from '../assets/modularKitchenWide.webp';
import imgBedroomFurniture from '../assets/bedroomFurniture.webp';
import imgLivingRoomFurniture from '../assets/livingRoomFurniture.webp';
import imgOfficeFurniture from '../assets/officeFurniture.webp';
import ExcelContext from './ExcelContext';
import { useEffect, useState, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'


const ServiceGallery = () => {

    const { excel, setExcel } = useContext(ExcelContext);
    const [serviceIndex, setServiceIndex] = useState(0);
    const kitchenRef = useRef(null);
    const officeRef = useRef(null);
    const livingRef = useRef(null);
    const bedroomRef = useRef(null);
    const serviceImagesRef = useRef(null);

    const services = [
        { title: 'Modular Kitchen', image: imgModularKitchenWide, ref: kitchenRef },
        { title: 'Office Furniture', image: imgOfficeFurniture, ref: officeRef },
        { title: 'Living Room Furniture', image: imgLivingRoomFurniture, ref: livingRef },
        { title: 'Bedroom Furniture', image: imgBedroomFurniture, ref: bedroomRef }
    ];

    const goTo = (page) => () => {
        if (excel.serviceGalleryScroll) {
            setExcel({ ...excel, page, serviceGalleryScroll: false });
        }
    }

    const handleAngleRightInteraction = () => {
        if (excel.serviceGalleryScroll) {
            setExcel({ ...excel, serviceGalleryScroll: false });
        }
        if (serviceIndex === services.length - 1) {
            setServiceIndex(0);
            handleServiceChange(0);
        } else {
            setServiceIndex(serviceIndex + 1);
            handleServiceChange(serviceIndex + 1);
        }
    }

    const handleAngleLeftInteraction = () => {
        if (excel.serviceGalleryScroll) {
            setExcel({ ...excel, serviceGalleryScroll: false });
        }
        if (serviceIndex === 0) {
            setServiceIndex(services.length - 1);
            handleServiceChange(services.length - 1);
        } else {
            setServiceIndex(serviceIndex - 1);
            handleServiceChange(serviceIndex - 1);
        }
    }

    const handleServiceChange = (index) => {
        if (services[index].ref && services[index].ref.current) {
            const scrollPosition = services[index].ref.current.offsetLeft;
            serviceImagesRef.current.scrollTo({
                left: scrollPosition,
                behavior: 'smooth'
            });
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (excel.serviceGalleryScroll) {
                setServiceIndex(prevIndex => {
                    handleServiceChange((prevIndex + 1) % services.length);
                    return (prevIndex + 1) % services.length
                });
            }
        }, 4000);

        return () => clearInterval(interval);

    }, [excel.serviceGalleryScroll]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setServiceIndex(parseInt(entry.target.id));
                }
            });
        }, { threshold: 0.5 }); // Adjust threshold as needed

        observer.observe(kitchenRef.current);
        observer.observe(officeRef.current);
        observer.observe(livingRef.current);
        observer.observe(bedroomRef.current);

        return () => {
            observer.disconnect();
        }
    }, []);

    return (
        <div className={styles.section}>
            <div className={styles.interaction}>
                <div className={styles.angleInteraction}>
                    <FontAwesomeIcon icon={faAngleLeft} onClick={handleAngleLeftInteraction} className={styles.icon} />
                    <h2 className={styles.title}>{services[serviceIndex].title}</h2>
                    <FontAwesomeIcon icon={faAngleRight} onClick={handleAngleRightInteraction} className={styles.icon} />
                </div>
                <button onClick={goTo("Shop")} className={styles.callToAction}>Get Yours</button>
            </div>
            <div className={styles.serviceImages} ref={serviceImagesRef}>
                {services.map((service, index) => (
                    <div key={index} ref={service.ref} id={index}>
                        <img src={service.image} />
                    </div>
                )
                )}
            </div>
        </div>
    )
}

export default ServiceGallery;