import styles from './Excel.module.css';
import Header from './Header';
import Main from './Main';
import ExcelContext from './ExcelContext';
import { useState } from 'react';
import Gallery from './Gallery';
import Shop from './Shop';
import Footer from './Footer';

const Excel = () => {
    const [excel, setExcel] = useState({ page: 'Home', serviceGalleryScroll: true })
    return (
        <div className={styles.body}>
            <ExcelContext.Provider value={{ excel, setExcel }}>
                <Header />
                {excel.page !== 'Gallery' && excel.page !== 'Shop' ? <Main /> : null}
                {excel.page === 'Gallery' ? <Gallery /> : null}
                {excel.page === 'Shop' ? <Shop /> : null}
                <Footer />
            </ExcelContext.Provider>
        </div>
    );
}

export default Excel;