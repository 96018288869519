import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleRoof, faHandshakeSimple, faFaceSmile, faPenRuler } from '@fortawesome/free-solid-svg-icons';
import styles from './AboutUs.module.css';
import { useContext, useEffect, useState } from 'react';
import ExcelContext from './ExcelContext';

const AboutUs = () => {
    // TODO Add Container for non mobile screens
    const { excel, setExcel } = useContext(ExcelContext);
    const [animate, setAnimate] = useState({fresh: true, section: '', article: ''});

    useEffect(() => {
        if(animate.fresh){
            if (excel.page === 'About'){
                setAnimate({ ...animate, fresh: false, section:' ' + styles.sectionAnimate, article: ' ' + styles.articleAnimate});
            }else{
                setAnimate({ ...animate, section:'', article: ''});
            }
        }
    }, [excel]);

    return (
        <div className={styles.section + animate.section}>
            <div className={styles.aboutArticles}>
            <article className={styles.article + animate.article}>
                <div className={styles.articleHeader}>
                    <FontAwesomeIcon icon={faPeopleRoof} className={styles.articleIcon} />
                    <h2 className={styles.articleTitle}>About Us</h2>
                </div>
                <p className={styles.articleDescription}>
                    With over 15 years of expertise, we specialize in crafting premium office furniture, elegant home furnishings, and bespoke modular kitchens designed to inspire and endure. We believe that every piece of furniture should reflect the highest standards of quality and aesthetics.
                </p>
            </article>
            <article className={styles.article + animate.article}>
                <div className={styles.articleHeader}>
                    <FontAwesomeIcon icon={faHandshakeSimple} className={styles.articleIcon} />
                    <h2 className={styles.articleTitle}>Quality Commitment</h2>
                </div>
                <p className={styles.articleDescription}>
                    We are committed to excellence in every piece we create. By selecting only the finest materials and employing skilled artisans, we ensure that our furniture is not just visually appealing but also durable and sustainable. Our quality control processes are stringent, guaranteeing that every product meets our high standards and your expectations.
                </p>
            </article>
            <article className={styles.article + animate.article}>
                <div className={styles.articleHeader}>
                    <FontAwesomeIcon icon={faFaceSmile} className={styles.articleIcon} />
                    <h2 className={styles.articleTitle}>Customer Experience</h2>
                </div>
                <p className={styles.articleDescription}>
                    Your satisfaction is at the core of everything we do. From the moment you step into our store or visit our website, to the delivery and installation of your furniture, we strive to provide a seamless and enjoyable experience. Our team is always here to assist, whether you need personalized design advice or after-sales support.
                </p>
            </article>
            <article className={styles.article + animate.article}>
                <div className={styles.articleHeader}>
                    <FontAwesomeIcon icon={faPenRuler} className={styles.articleIcon} />
                    <h2 className={styles.articleTitle}>Design philosophy</h2>
                </div>
                <p className={styles.articleDescription}>
                    Our design philosophy centers on innovation, customization, and sustainability. We merge contemporary trends with timeless styles to create functional yet beautiful pieces that complement any space. Our modular solutions offer endless possibilities, ensuring that your furniture not only fits your space perfectly but also reflects your personal style.
                </p>
            </article>
            </div>
        </div>
    )
}

export default AboutUs;